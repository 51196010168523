import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { email, maxLength, required } from "vuelidate/lib/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { RegisterMode } from "@/components/popups/registration";
import validation from "@/mixins/validation";

export default {
  name: "distributor-registration",
  data() {
    return {
      agree: false,
      payload: {
        register_mode: RegisterMode.Distributor,
        phone_country: "",
        email: "",
        name: "",
        secondName: "",
        phone: "",
        work: "",
        files: [],
        city: "",
        lastName: "",
      },
      isValid: false,
      isValidTown: false,
      validationErrors: {},
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      name: {
        required,
      },
      secondName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      files: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
    },
  },
  watch: {
    agree() {
      this.$refs.agreeError.innerHTML = !this.agree ? "Потрібно погодитись з політикою конфіденційності" : "";
    },
  },
  created() {
    this.payload.phone = this.verifyCodeResponse.phone;
    document.getElementById("html").classList.remove("hide");
    this.onRequestTown(this.payload.city);
    this.getCountries();
  },
  computed: {
    ...mapGetters({
      loadingRegistration: "auth/loadingRegistration",
      agentCode: "auth/agentCode",
      towns: "order/towns",
      verifyCodeResponse: "auth/verifyCodeResponse",
    }),
    nameErrors() {
      return this.computeErrors("payload", "name", {
        required: "validationRequired",
      });
    },
    secondNameErrors() {
      return this.computeErrors("payload", "secondName", {
        required: "validationRequired",
      });
    },
    lastNameErrors() {
      return this.computeErrors("payload", "lastName", {
        required: "validationRequired",
      });
    },
    cityErrors() {
      return this.computeErrors("payload", "city", {
        required: "validationRequired",
      });
    },
    emailErrors() {
      return this.computeErrors("payload", "email", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        email: "validationEmail",
      });
    },
    workErrors() {
      return this.computeErrors("payload", "work", {});
    },
    filesErrors() {
      return this.computeErrors("payload", "files", {
        required: "maxFileLoading",
      });
    },
  },
  methods: {
    ...mapActions({
      registrations: "auth/REGISTRATION",
      getCountries: "auth/GET_COUNTRIES",
      fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
    }),
    ...mapMutations({
      changeRegisterAgent: "popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER",
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      changeRegisterDistributorPopup: "auth/SHOW_DISTRIBUTOR_REGISTER_POPUP",
    }),
    changePopup() {
      this.changeLoginPopup(true);
      this.changeRegisterDistributorPopup(false);
    },
    closeLoginPopup() {
      this.changeRegisterDistributorPopup(false);
      this.changeLoginPopup(true);
    },
    onRequestTown(val) {
      const formData = JSON.stringify({
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: val,
        },
        apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
      });
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.Description === this.payload.city);

        if (!currentTown) {
          console.log("currentTown not found: ", currentTown);
        }
      });
    },

    handleFileChange(evt) {
      let arr = Array.from(evt.target.files);
      this.payload.files = this.payload.files.concat(arr);
    },
    removeFile(file) {
      const index = this.payload.files.findIndex(e => e.name === file.name);
      this.payload.files.splice(index, 1);
    },
    transformData() {
      const parsedNumber = parsePhoneNumberFromString(this.payload.phone);
      if (parsedNumber) {
        this.payload.phone_country = parsedNumber.country;
      } else {
        this.payload.phone_country = "";
      }

      this.payload.confirm_token = this.verifyCodeResponse.confirmToken;
      return _.mapKeys(this.payload, (value, key) => _.snakeCase(key));
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === "files") {
          for (let j in obj[i]) {
            fd.append(`files[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i]);
        }
      }

      return fd;
    },
    removeAttr(ref) {
      this.$refs[`${ref}`].removeAttribute("readonly");
    },
    async submit() {
      this.$refs.agreeError.innerHTML = !this.agree ? "Потрібно погодитись з політикою конфіденційності" : "";

      this.$v.$touch();

      if ((this.$v.$invalid && !this.payload.files.length) || !this.agree) {
        this.$toasted.error("invalid");
        return;
      }

      if (!this.payload.files.length) {
        this.$toasted.error("Файли обов'язкові");
        return;
      }

      try {
        await this.registrations(this.parsFormData(this.transformData()));

        this.changeRegisterDistributorPopup(false);
        this.changeRegisterAgent(true);
        window.dataLayer.push({
          'event' : 'sign_up',
          'eventCategory' : 'sign_up',
          'eventAction' : 'sign_up',
          'loginMethod' : 'email'
        });
      } catch (error) {
        this.handleRegistrationErrors(error);
      }
    },
    handleRegistrationErrors(error) {
      if (!error?.data?.errors) {
        return;
      }

      const processErrors = ["email", "phone", "assignTo"];
      for (const processErrorsKey of processErrors) {
        const errors = error.data.errors[processErrorsKey];
        if (!errors || !errors[0]) {
          continue;
        }

        this.$toasted.error(errors[0]);
      }
    },
  },
};
