import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";

interface State {
  promotionsBrands: any;
  loadingBrands: boolean;
}

const state: State = {
  promotionsBrands: null,
  loadingBrands: false,
};

const getters = {
  promotionsBrands: (state: State) => state.promotionsBrands,
  loadingBrands: (state: State) => state.loadingBrands,
};

const actions = {
  FETCH_PROMOTIONS_BRANDS: async ({ commit }: ActionContext<State, RootState>, params: any) => {
    try {
      commit("SET_LOADING_BRANDS", true);
      const response = await $http.get("v1/sales/brands", { params });
      commit("SET_PROMOTIONS_BRANDS", response.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit("SET_LOADING_BRANDS", false);
    }
  },
};

const mutations = {
  SET_PROMOTIONS_BRANDS(state: State, brands: any) {
    state.promotionsBrands = brands;
  },
  SET_LOADING_BRANDS(state: State, isLoading: boolean) {
    state.loadingBrands = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
