import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { Product } from "@/types/shop";
import type { IProductFaqPayload, IProductFeedbackPayload } from "@/store/modules/types/card";

interface State {
  product: Product | null;
  productLoading: boolean;
}

const state: State = {
  product: null,
  productLoading: false,
};

const getters = {
  product: (state: State) => state.product,
  productLoading: (state: State) => state.productLoading,
};

const actions = {
  GET_PRODUCT: async ({ commit }: ActionContext<State, RootState>, payload: { slug: string; value?: number }) => {
    commit("CHANGE_PRODUCT_LOADING", true);
    commit("RESET_PRODUCT");
    try {
      const response = await $http.get<{ data: Product }>(`v1/products/${payload.slug}`, {
        params: {
          value: payload.value || 0,
        },
      });
      commit("SET_PRODUCT", response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit("CHANGE_PRODUCT_LOADING", false);
    }
  },
  SEND_FEEDBACK: async ({ commit }: ActionContext<State, RootState>, payload: IProductFeedbackPayload) => {
    try {
      await $http.post(`v1/products/feedbacks`, payload);
    } catch (e) {
      throw e;
    }
  },
  SEND_QUESTION: async ({ commit }: ActionContext<State, RootState>, payload: IProductFaqPayload) => {
    try {
      await $http.post(`v1/products/faq`, payload);
    } catch (e) {
      throw e;
    }
  },
  GET_SAME_PRODUCTS: async ({ commit }: ActionContext<State, RootState>, slug: string) => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/products/${slug}/buy_with`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  SET_PRODUCT(state: State, product: Product) {
    state.product = product;
  },
  RESET_PRODUCT(state: State) {
    state.product = null;
  },
  CHANGE_PRODUCT_LOADING(state: State, status: boolean) {
    state.productLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
