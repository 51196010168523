import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import type { Seminar, SeminarCity, SeminarTheme, SeminarType } from "@/types/seminars";
import type { PaginatorData } from "@/types/api";

interface IStoreSeminarPayload {
  seminar_id: number;
  name: string;
  last_name: string;
  phone: string;
  comment?: string;
}

interface State {
  showSeminarPopup: boolean;
  currentSeminar: Seminar | null;
  showSuccessPopup: boolean;
}

type PaginatorInfo = { page: number; perPage: number };

type IFiltersType = {
  theme?: number;
  city?: number;
  type?: number;
  date_from?: string;
  date_to?: string;
};

const state: State = {
  showSeminarPopup: false,
  currentSeminar: null,
  showSuccessPopup: false,
};

const getters = {
  showSeminarPopup: (state: State) => state.showSeminarPopup,
  currentSeminar: (state: State) => state.currentSeminar,
  showSuccessPopup: (state: State) => state.showSuccessPopup,
};

const actions = {
  GET_SEMINARS: async (state: ActionContext<State, RootState>, filtersParams: IFiltersType & PaginatorInfo) => {
    try {
      const response = await $http.get<PaginatorData<Seminar[]>>(`v1/seminars`, {
        params: filtersParams,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  },
  GET_SEMINAR_FILTERS: async (state: ActionContext<State, RootState>, type: string) => {
    try {
      type IFilters = {
        types: Array<SeminarType>;
        cities: Array<SeminarCity>;
        themes: Array<SeminarTheme>;
      };

      const response = await $http.get<{ data: IFilters }>(`v1/seminars/filters`, {
        params: {
          type: type,
        },
      });

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  SEMINAR_REGISTRATION: async (state: ActionContext<State, RootState>, payload: IStoreSeminarPayload) => {
    try {
      await $http.post(`v1/seminars/request`, payload);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {
  SHOW_SEMINAR_POPUP(state: State, status: boolean) {
    state.showSeminarPopup = status;
  },
  SAVE_CURRENT_SEMINAR(state: State, seminar: Seminar) {
    state.currentSeminar = seminar;
  },
  SHOW_SUCCESS_SEMINAR(state: State, status: boolean) {
    state.showSuccessPopup = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
