import { mapActions, mapGetters, mapMutations } from "vuex";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { email, maxLength, minLength, required, sameAs } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export const RegisterMode = {
  None: "none",
  Regular: "regular",
  Professional: "professional",
  Distributor: "distributor",
};

export default {
  name: "registration",
  data() {
    return {
      RegisterMode,
      agree: false,
      payload: {
        register_mode: RegisterMode.Regular,
        phone_country: "",
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        second_name: "",
        assign_to: "",
        phone: "",
        work: "",
      },
      validationErrors: {},
      validationFiles: false,
      showPassword: false,
      registrationMode: RegisterMode.None,
      registrationModes: [
        {
          mode: RegisterMode.Regular,
          title: "Реєстрація пацієнта",
          enabled: true,
        },
        {
          mode: RegisterMode.Professional,
          title: "Реєстрація професіонала",
          enabled: true,
        },
        {
          mode: RegisterMode.Distributor,
          title: "Реєстрація дистриб'ютора",
          enabled: false,
        },
      ].filter(({ enabled }) => enabled),
      rule: {
        require: true,
      },
    };
  },
  mixins: [validation],
  validations: {
    payload: {
      phone: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(120),
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(120),
        sameAsPassword: sameAs("password"),
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(120),
        sameAsPassword: sameAs("password"),
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
      second_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(120),
      },
    },
  },
  watch: {
    agree() {
      this.$refs.agreeError.innerHTML = !this.agree ? "Потрібно погодитись з політикою конфіденційності" : "";
    },
    registrationMode: function () {
      this.changeRegisterAgentPopup(false);
      this.changeRegistrationPopup(false);
      this.changeRegisterDistributorPopup(false);
      if (!this.verifyCodeResponse) {
        this.showSmsRegisterPopup(true);
        return;
      }

      switch (this.registrationMode) {
        case RegisterMode.Regular:
          this.changeRegistrationPopup(true);
          break;
        case RegisterMode.Professional:
          this.changeRegisterAgentPopup(true);
          break;
        case RegisterMode.Distributor:
          this.changeRegisterDistributorPopup(true);
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      loadingRegistration: "auth/loadingRegistration",
      verifyCodeResponse: "auth/verifyCodeResponse",
    }),
    passwordErrors() {
      return this.computeErrors("payload", "password", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        minLength: { message: "validationMin", count: 8 },
      });
    },
    passwordConfirmationErrors() {
      return this.computeErrors("payload", "password_confirmation", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        sameAsPassword: "validationSameAs",
        minLength: { message: "validationMin", count: 8 },
      });
    },
    emailErrors() {
      return this.computeErrors("payload", "email", {
        maxLength: { message: "validationMax", count: 120 },
        required: "validationRequired",
        email: "validationEmail",
      });
    },
    nameErrors() {
      return this.computeErrors("payload", "name", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 3 },
        maxLength: { message: "validationMax", count: 120 },
      });
    },
    secondNameErrors() {
      return this.computeErrors("payload", "second_name", {
        required: "validationRequired",
        minLength: { message: "validationMin", count: 3 },
        maxLength: { message: "validationMax", count: 120 },
      });
    },
  },
  created() {
    this.payload.phone = this.verifyCodeResponse?.phone;

    document.getElementById("html").classList.add("hide");
  },
  methods: {
    ...mapMutations({
      changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
      changeSuccessRegisterPopup: "popups/CHANGE_SUCCESS_REGISTER_POPUP",
      changeRegisterAgentPopup: "auth/SHOW_AGENT_REGISTER_POPUP",
      changeRegisterDistributorPopup: "auth/SHOW_DISTRIBUTOR_REGISTER_POPUP",
      showSmsRegisterPopup: "auth/SHOW_PHONE_REGISTER_POPUP",
    }),
    ...mapActions({
      registrations: "auth/REGISTRATION",
      fetchUser: "profile/FETCH_USER_DATA",
    }),
    changePopup() {
      this.changeLoginPopup(true);
      this.changeRegistrationPopup(false);
    },
    removeAttr(ref) {
      this.$refs[`${ref}`].removeAttribute("readonly");
    },
    async submit() {
      this.$refs.agreeError.innerHTML = !this.agree ? "Потрібно погодитись з політикою конфіденційності" : "";

      this.$v.$touch();

      if (this.$v.$invalid || !this.agree) {
        return;
      }

      const parsedNumber = parsePhoneNumberFromString(this.payload.phone);

      if (parsedNumber) {
        this.payload.phone_country = parsedNumber.country;
      } else {
        this.payload.phone_country = "";
      }

      this.payload.confirm_token = this.verifyCodeResponse.confirmToken;

      try {
        await this.registrations(this.payload);

        this.changeRegistrationPopup(false);
        window.dataLayer.push({
          'event' : 'sign_up',
          'eventCategory' : 'sign_up',
          'eventAction' : 'sign_up',
          'loginMethod' : 'email'
        });


        this.fetchUser().then(() => {
          if (this.payload.assign_to.length) {
            this.changeRegistrationPopup(false);
            this.$router
              .push({ name: "profile" })
              .then(() => {})
              .catch(() => {});
          }
        });
      } catch (error) {
        this.handleRegistrationErrors(error);
      }
    },
    handleRegistrationErrors(error) {
      if (!error?.data?.errors) {
        return;
      }

      const processErrors = ["email", "phone", "assignTo"];
      for (const processErrorsKey of processErrors) {
        const errors = error.data.errors[processErrorsKey];
        if (!errors || !errors[0]) {
          continue;
        }

        this.$toasted.error(errors[0]);
      }
    },
  },
};
