import { mapGetters, mapMutations } from "vuex";

export default {
  name: "seminar-info",
  computed: {
    ...mapGetters({
      seminarInfoContent: "popups/showSeminarInfoContent",
    }),
  },
  methods: {
    ...mapMutations({
      changeSeminarInfo: "popups/SHOW_SEMINAR_INFO_POPUP",
      changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
    }),

    showLoginModal() {
      this.changeLoginPopup(true);
      this.changeSeminarInfo(false);
    }
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
};
