import Vue from "vue";
import type { ModuleTree } from "vuex";
import Vuex from "vuex";

Vue.use(Vuex);

interface ModuleState {}

export interface RootState {}

import documents from "./modules/documents";
import home from "./modules/home";
import remainings from "./modules/remainings";
import setting from "./modules/setting";
import seminars from "./modules/seminars";
import courses from "./modules/courses";
import card from "./modules/card";
import products from "./modules/products";
import profile from "./modules/profile";
import system from "./modules/system";
import favorites from "./modules/favorites";
import popups from "./modules/popups";
import order from "./modules/order";
import basket from "./modules/basket";
import metodist from "./modules/metodist";
import auth from "./modules/auth";
import search from "./modules/search";
import pages from "./modules/pages";
import managers from "./modules/managers";
import promotions from "./modules/promotions";
import brands from "./modules/brands";

const modules: ModuleTree<ModuleState> = {
  documents,
  home,
  remainings,
  setting,
  seminars,
  courses,
  card,
  products,
  profile,
  system,
  favorites,
  popups,
  order,
  basket,
  metodist,
  auth,
  search,
  pages,
  managers,
  promotions,
  brands,
};

const store = new Vuex.Store({
  modules,
});

// @ts-ignore
export default store;
