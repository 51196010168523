import { mapGetters } from "vuex";

export default {
    name: "MessageBlock",
    computed: {
        ...mapGetters({
            variables: `setting/variables`,
        }),
        getViberLink() {
            return this.variables?.messengers?.viberLink;
        },
        getTelegramLink() {
            return this.variables?.messengers?.telegramLink;
        },
    }
}