import { mapGetters } from "vuex";
import { OrderType } from "@/modules/buy-flow/pages/order";

export default {
  name: "success-order",
  props: {
    payload: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isDistributor: "profile/isDistributor",
    }),
  },
  created() {
    document.getElementById("html").classList.add("hide");
  },
  methods: {
    getText() {
      if (this.isDistributor) {
        let text = "Дякуємо за замовлення та вибір SkinOn!";

        if (this.payload.documentType === OrderType.CLIENTSALE) {
          text = "Ваш звіт по продажам отриманий. Дякуємо за співпрацю!";
        }

        return text + "<br> Переглянути статус та суму взаєморозрахунків Ви можете в своєму особистому кабінеті.";
      }

      let text = "Дякуємо за Ваше замовлення!";

      if (this.payload.withWebinars) {
        text +=
          " <br/> Після підтвердження оплати вам буде надано доступ до навчання в Особистому кабінеті, в розділі – Моя платформа онлайн навчань.";
      }

      text += "<br/> Очікуйте підтвердження від експерта SkinOn та <br> реквізити для оплати.";
      return text;
    },
  },
};
