import { globalLoader } from "@/helpers/variables";
import { $http } from "@/utils/https";
import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import userHelpers from "@/helpers/user-helpers";
import { type ClientUser, type User, UserTypes } from "@/types/user";
import type { Order } from "@/types/order";
import type { PaginatorData } from "@/types/api";
import type { IUploadFilesPayload, IUserUpdatePayload } from "@/store/modules/types/profile";

const OrderStatusFilter = {
  FINISHED: "finished",
  in_progress: "in_progress",
  all: "all",
};

interface State {
  adminData: any;

  user: User | null;
  updateUserLoading: boolean;
  clientUserData: User | null;
}

type PaginatorInfo = { page: number; perPage?: number };

const state: State = {
  adminData: null,

  user: null,
  updateUserLoading: false,
  clientUserData: null,
};

const getters = {
  adminData: (state: State) => state.adminData,
  user: (state: State) => state.user,
  updateUserLoading: (state: State) => state.updateUserLoading,
  clientUserData: (state: State) => state.clientUserData,
  isDistributor: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Distributor),
  isProfessional: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Professional),
  isUserWithCode: (state: State): boolean => userHelpers.checkUserType(state.user, UserTypes.Authorized_user),
  isManager: (state: State): boolean => userHelpers.isManager(state.user),
  canUseBonuses: (state: State): boolean => !!state.user?.canUseBonuses,
};

const actions = {
  FETCH_USER_DATA: async ({ commit }: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get<{ data: User }>("v1/auth/me");
      commit("SET_USER_DATA", response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  UPDATE_USER_DATA: async ({ commit }: ActionContext<State, RootState>, payload: IUserUpdatePayload) => {
    try {
      commit("UPDATE_USER_DATA_LOADING", true);
      const response = await $http.put<{ data: User }>("v1/user", payload);
      commit("SET_USER_DATA", response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit("UPDATE_USER_DATA_LOADING", false);
    }
  },
  PROFESSIONAL_ADD_FILES: async ({ commit }: ActionContext<State, RootState>, payload: IUploadFilesPayload) => {
    try {
      commit("UPDATE_USER_DATA_LOADING", true);
      const response = await $http.post<{ data: User }>("v1/user/files", payload);
      commit("SET_USER_DATA", response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit("UPDATE_USER_DATA_LOADING", false);
    }
  },
  LINK_WITH_GOOGLE: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post("v1/oauth/google/link", payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  LINK_WITH_FACEBOOK: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post("v1/oauth/facebook/link", payload);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  UNLINK_WITH_GOOGLE: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post("v1/oauth/google/unlink");
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  UNLINK_WITH_FACEBOOK: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
    globalLoader(true);
    try {
      const result = await $http.post("v1/oauth/facebook/unlink");
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },

  GET_CLIENTS: async ({ commit }: ActionContext<State, RootState>) => {
    try {
      const response = await $http.get<{ data: ClientUser[] }>(`v1/user/clients`);

      return response.data;
    } catch (e) {
      throw e;
    }
  },
  GET_CLIENT_ORDER: async (
    { commit }: ActionContext<State, RootState>,
    payload: PaginatorInfo & {
      clientId: number;
    }
  ) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/user/clients/${payload.clientId}/orders`, {
        params: {
          page: payload.page,
          perPage: payload.perPage,
        },
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  },
  GET_CLIENT_USER_DATA: async ({ commit }: ActionContext<State, RootState>, clientId: number | string) => {
    try {
      const responseClient = await $http.get<{ data: User }>(`v1/user/clients/${clientId}`);
      commit("SET_CLIENT_USER_DATA", responseClient.data.data);
    } catch (e) {
      throw e;
    }
  },
  GET_CURRENT_ORDERS: async ({ commit }: ActionContext<State, RootState>, payload: PaginatorInfo) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/orders`, {
        params: {
          orders_status: OrderStatusFilter.in_progress,
          page: payload.page,
          perPage: payload.perPage || 15,
        },
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HISTORY_ORDERS: async ({ commit }: ActionContext<State, RootState>, payload: PaginatorInfo) => {
    try {
      const response = await $http.get<PaginatorData<Order[]>>(`v1/orders`, {
        params: {
          orders_status: OrderStatusFilter.FINISHED,
          page: payload.page,
          perPage: payload.perPage || 15,
        },
      });

      return response.data;
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  setAdminData(state: State, data: any) {
    let currentLinks: Array<any> = [];
    if (!data.resetLinks) {
      currentLinks = state.adminData?.links || [];
    }
    const newLinks: Array<any> = data.links || [];

    // @ts-ignore
    currentLinks.push(...newLinks);

    currentLinks = currentLinks.filter(
      (item: any, index: number, self: any) => index === self.findIndex((t: any) => t.url === item.url)
    );

    state.adminData = {
      adminHome: data.adminHome,
      links: currentLinks,
    };
  },
  SET_USER_DATA(state: State, user: User) {
    state.user = user;
  },
  SET_CLIENT_USER_DATA(state: State, data: User) {
    state.clientUserData = data;
  },
  UPDATE_USER_DATA_LOADING(state: State, status: boolean) {
    state.updateUserLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
