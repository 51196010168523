import { $http } from "@/utils/https";
import type { RootState } from "@/store";
import type { ActionContext } from "vuex";
import type { AxiosResponse } from "axios";
import type {
  IChangePasswordPayload,
  IChangePasswordResponse,
  ILoginPayload,
  ILoginResponse,
  ILogoutResponse,
  IRegisterPayload,
  IResetPasswordPayload,
  IResetPasswordResponse,
  ISendCodePayload,
  ISendCodeResponse,
  ISetNewPasswordPayload,
  ISetNewPasswordResponse,
  IVerifyCodePayload,
  IVerifyCodeResponse,
  TRegistrationResponse,
} from "@/store/modules/types/auth";

interface State {
  showPhoneRegisterPopup: boolean;
  isAuthenticated: boolean;
  passwordLoading: boolean;
  loadingRegistration: boolean;
  loadingSingIn: boolean;
  isLoading: boolean;
  agentCode: string | null;
  agentRegister: boolean;
  distributorRegister: boolean;
  smsCodeRequested: boolean;
  verifyCodeResponse: IVerifyCodeResponse | null;
  smsCodeLoading: boolean;
  verifyCodeLoading: boolean;
}

const state: State = {
  verifyCodeLoading: false,
  smsCodeLoading: false,
  verifyCodeResponse: null,
  showPhoneRegisterPopup: false,
  smsCodeRequested: false,
  passwordLoading: false,
  isAuthenticated: false,
  loadingRegistration: false,
  loadingSingIn: false,
  isLoading: false,
  agentCode: null,
  agentRegister: false,
  distributorRegister: false,
};

const getters = {
  verifyCodeLoading: (state: State) => state.verifyCodeLoading,
  smsCodeLoading: (state: State) => state.smsCodeLoading,
  verifyCodeResponse: (state: State) => state.verifyCodeResponse,
  showPhoneRegisterPopup: (state: State) => state.showPhoneRegisterPopup,
  smsCodeRequested: (state: State) => state.smsCodeRequested,
  passwordLoading: (state: State) => state.passwordLoading,
  distributorRegister: (state: State) => state.distributorRegister,
  agentRegister: (state: State) => state.agentRegister,
  isAuthenticated: (state: State) => state.isAuthenticated,
  loadingRegistration: (state: State) => state.loadingRegistration,
  loadingSingIn: (state: State) => state.loadingSingIn,
  isLoading: (state: State) => state.isLoading,
  agentCode: (state: State) => state.agentCode,
};

const actions = {
  GET_COUNTRIES: async () => {
    try {
      const axios = require("axios");

      const options = {
        method: "GET",
        url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries`,
        params: { limit: "10" },
        headers: {
          "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
          "X-RapidAPI-Key": "e9fcccba0bmshf5f52161a5b7937p140b31jsn13e1efe61946",
        },
      };

      axios.request(options).then(() => {});
    } catch (e) {
      throw e;
    }
  },

  VERIFY_CODE: async ({ commit }: ActionContext<State, RootState>, payload: IVerifyCodePayload) => {
    commit("VERIFY_CODE_LOADING", true);
    try {
      const result = await $http.post<IVerifyCodeResponse>("v1/auth/verify-code", payload);
      commit("SET_VERIFY_CODE", result.data);
      return result;
    } catch (e) {
      throw e;
    } finally {
      commit("VERIFY_CODE_LOADING", false);
    }
  },
  SEND_CODE: async ({ commit }: ActionContext<State, RootState>, payload: ISendCodePayload) => {
    commit("SMS_CODE_LOADING", true);
    try {
      const result = await $http.post<ISendCodeResponse>("v1/auth/send-code", payload);
      commit("SET_SMS_CODE");
      return result;
    } catch (e) {
      throw e;
    } finally {
      commit("SMS_CODE_LOADING", false);
    }
  },
  GET_TOKEN: async ({ commit }: ActionContext<State, RootState>, payload: ILoginPayload) => {
    commit("CHANGE_LOADING_SING_IN", true);
    try {
      // @ts-ignore
      const result: AxiosResponse<ILoginResponse> = await $http.post<ILoginResponse>("v1/auth/login", payload, {
        // @ts-ignore
        errorHandle: false,
      });
      commit("SET_TOKEN", result.data.data.accessToken);
      window.location.reload();
    } catch (e) {
      throw e;
    } finally {
      commit("CHANGE_LOADING_SING_IN", false);
    }
  },
  CHANGE_PASSWORD: async ({ commit }: ActionContext<State, RootState>, payload: IChangePasswordPayload) => {
    commit("NEW_PASSWORD_LOADING", true);
    try {
      await $http.post<IChangePasswordResponse>("v1/user/password-change", payload);
    } catch (e) {
      throw e;
    } finally {
      commit("NEW_PASSWORD_LOADING", false);
    }
  },
  SING_IN_WITH_FACEBOOK: async ({ commit }: ActionContext<State, RootState>, payload: any) => {
    commit("CHANGE_LOADING_SING_IN", true);
    try {
      const result = await $http.post("v1/auth/social_login", payload);
      commit("SET_TOKEN", result.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit("CHANGE_LOADING_SING_IN", false);
    }
  },
  LOGOUT: async ({ commit }: ActionContext<State, RootState>) => {
    try {
      return await $http.post<ILogoutResponse>("v1/auth/logout");
    } catch (e) {
      throw e;
    } finally {
      commit("REMOVE_TOKEN");
    }
  },
  REGISTRATION: async ({ commit }: ActionContext<State, RootState>, payload: IRegisterPayload) => {
    try {
      commit("SET_LOADING_REGISTRATION", true);
      const result = await $http.post<TRegistrationResponse>("v1/auth/register", payload);
      if (result.data.data.code) {
        commit("SET_AGENT_CODE", result.data.data.code);
        setTimeout(() => {
          commit(
            "popups/CHANGE_SUCCESS_REGISTER_POPUP",
            {
              /*percent: result.data.data.percent*/
            },
            { root: true }
          );
        }, 500);
      }
      // @ts-ignore
      const token = result.data.data.accessToken;
      if (token) {
        commit("SET_TOKEN", token);
        setTimeout(() => {
          commit(
            "popups/CHANGE_SUCCESS_REGISTER_POPUP",
            {
              /*percent: result.data.data.percent*/
            },
            { root: true }
          );
        }, 500);
      }
    } catch (e) {
      throw e;
    } finally {
      commit("SET_LOADING_REGISTRATION", false);
    }
  },
  RESET_PASSWORD: async ({ commit }: ActionContext<State, RootState>, payload: IResetPasswordPayload) => {
    commit("CHANGE_LOADING", true);
    try {
      await $http.post<IResetPasswordResponse>("v1/auth/reset", payload);
    } catch (e) {
      throw e;
    } finally {
      commit("CHANGE_LOADING", false);
    }
  },
  RESTORE_PASSWORD: async ({ commit }: ActionContext<State, RootState>, payload: ISetNewPasswordPayload) => {
    commit("CHANGE_LOADING", true);
    try {
      const result = await $http.post<ISetNewPasswordResponse>("v1/auth/restore", payload);
      commit("SET_TOKEN", result.data.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit("CHANGE_LOADING", false);
    }
  },
};

const mutations = {
  VERIFY_CODE_LOADING(state: State, status: boolean) {
    state.verifyCodeLoading = status;
  },
  SMS_CODE_LOADING(state: State, status: boolean) {
    state.smsCodeLoading = status;
  },
  SHOW_PHONE_REGISTER_POPUP(state: State, status: boolean) {
    state.showPhoneRegisterPopup = status;
  },
  SET_SMS_CODE(state: State) {
    state.smsCodeRequested = true;
  },
  SET_TOKEN(state: State, token: string) {
    state.isAuthenticated = true;
    localStorage.setItem("user_token", token);
  },
  REMOVE_TOKEN(state: State) {
    localStorage.removeItem("user_token");
    state.isAuthenticated = false;
    setTimeout(() => {
      location.reload();
    }, 200);
  },
  SET_VERIFY_CODE(state: State, data: IVerifyCodeResponse) {
    state.verifyCodeResponse = data;
  },
  SET_LOADING_REGISTRATION(state: State, status: boolean) {
    state.loadingRegistration = status;
  },
  CHANGE_LOADING(state: State, status: boolean) {
    state.isLoading = status;
  },
  CHANGE_LOADING_SING_IN(state: State, status: boolean) {
    state.loadingSingIn = status;
  },
  SET_AGENT_CODE(state: State, data: string | null) {
    state.agentCode = data;
  },
  SHOW_AGENT_REGISTER_POPUP(state: State, status: boolean) {
    state.agentRegister = status;
  },
  SHOW_DISTRIBUTOR_REGISTER_POPUP(state: State, status: boolean) {
    state.distributorRegister = status;
  },
  NEW_PASSWORD_LOADING(state: State, status: boolean) {
    state.passwordLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
