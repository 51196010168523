
import { mapActions, mapGetters } from "vuex";
import Hidden from "@/components/hidden/index.vue";
import MainHeader from "@/components/header/index.vue";
import MainFooter from "@/components/footer/index.vue";
import { isIPad, isMobile } from "@/helpers/variables";
import MessageBlock from "@/components/@ui/messanger-block/index.vue";
import ScrollToTop from "@/components/@ui/scroll-to-top/index.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "app",
  data() {
    return {
      isShowSocials: false,
    };
  },
  components: {
    Hidden,
    MainHeader,
    MainFooter,
    MessageBlock,
    ScrollToTop
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      globalLoader: "system/globalLoader",
    }),
  },
  created() {
    if (this.isAuthenticated) {
      this.fetchUser();
      this.getFavorites();
    }
    this.getVariables();
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector("body")?.classList.add("hover");
    }
    if (!isIPad.iOS()) {
      document.querySelector("body")?.classList.add("iPad");
    }
  },
  methods: {
    ...mapActions({
      fetchUser: "profile/FETCH_USER_DATA",
      getFavorites: "favorites/GET_FAVORITES_LIST",
      getVariables: "setting/GET_VARIABLES",
    }),
  },
});
